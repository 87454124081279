<script>
import {indicadorEstoque} from "@/components/defaults/indicadorestoque";

export default {
  props: { currentEmpresa: { type: Object } },
  data() {
    return {
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0
      },
      listIndicadores: [],
      loader: {
        get: false
      },
    }
  },
  mounted() {
    this.$emit('newTitle', 'Incluir Local de Estoque');
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    setPost() {
      this.loader.get = true;
      setTimeout(function(){
          this.loader.get = false;
        }, 2000);
      this.$emit('doPost', this.localEstoque);
    },
  },
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <form id="form-locais-estoque" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_pagamento_id" class="col-form-label">Código</label>
                <div class="col">
                  <input disabled v-model="localEstoque.id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="tipo_pagamento_id">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
              <div class="form-group">
                <label for="tipo_pagamento_descricao" class="col-form-label">Nome do Local de Estoque</label>
                  <div class="col">
                    <input v-model="localEstoque.descricao" class="form-control" type="text" placeholder="PADRAO, LOJA, DEPOSITO..." id="tipo_pagamento_descricao">
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-6">
              <b-form-group label="Indicador de Estoque" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                 id="locais-estoque-tpag"
                 v-model="localEstoque.ind_est"
                 :options="listIndicadores"
                 :aria-describedby="ariaDescribedby"
                 name="ind_est"
                 stacked
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="locais-estoque-touch"
                   v-model="localEstoque.status"
                   name="touch"
                   value="1"
                   unchecked-value="0"
                  >
                    Ativo
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button v-if="!loader.get" class="btn btn-light" @click="setPost">Gravar</button>
      <i v-if="loader.get" class="fa fa-spinner fa-4x fa-spin text-success"></i>
    </div>
  </div>
</div>
</template>